<template>
  <div class="block">
<!--    <TextSlider />-->
    <div class="container-mobile">
      <h2 class="block-title">
        Competencies in one place<br>
      </h2>
      <div class="block-content">
        <div class="block-content-right">
          <h3 class="block-content-right-title">
            Automate digital products. Any data and capabilities at your fingertips.
          </h3>
          <p class="block-content-right-description">
            We have a lot of experience in project management and team aggregation.
          </p>
        </div>
        <div class="block-content-left">
          <TextSliderMobile />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
</script>

<style lang="scss" scoped>
.block {
  position: relative;
  height: auto;
  width: 100%;
  background-color: #030303;
  padding-bottom: get-vw(150px);
  padding-top: get-vw(64px);
  font-family: Inter,sans-serif;
  &-title {
    background: linear-gradient(90deg,#ffb076 -38.94%,#b9f9d1 109.81%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 600;
    letter-spacing: get-vw(-.1px);
    color: transparent;
    margin-top: get-vw(100px);
    font-size: get-vw(160px);
    line-height: 118%;
    @media (orientation: landscape){
      margin-top: get-vw(60px);
      font-size: get-vw(80px);
    }
  }
  &-content {
    background: linear-gradient(123.93deg,#101217 29.89%,#090a0d 100%);
    border-radius: get-vw(20px);
    overflow: hidden;
    margin-top: get-vw(70px);
    display: flex;
    flex-direction: column;
    min-height: get-vw(500px);
    &-left {
      display: flex;
      justify-content: center;
      align-items: center;
      height: get-vw(1000px);
      margin-top: get-vw(200px);
      @media (orientation: landscape){
        height: get-vw(700px);
        margin-top: get-vw(200px);
      }
    }
    &-right {
      font-style: normal;
      padding: get-vw(120px);
      display: flex;
      flex-direction: column;
      @media (orientation: landscape){
        padding: get-vw(70px);
      }
      &-title {
        color: #FFFFFF;
        font-weight: 500;
        font-size: get-vw(110px);
        margin: 0 0 get-vw(20px);
        line-height: 115%;
        font-family: Inter,sans-serif;
        text-transform: none;
        letter-spacing: normal;
        @media (orientation: landscape){
          font-size: get-vw(70px);
        }
      }
      &-description {
        color: #8b8b8c;
        font-weight: 400;
        font-size: get-vw(60px);
        margin: 0 0 get-vw(20px);
        line-height: 145%;
        font-family: Inter,sans-serif;
        @media (orientation: landscape){
          font-size: get-vw(40px);
        }
      }
    }
  }
}
</style>
